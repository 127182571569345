import React, { useEffect, useState } from 'react';
import axios from '../../axios';

function Dashboard(props) {
  const [stats, setStats] = useState({});

  const fetchStats = async () => {
    try {
      const res = await axios.get('/stats');
      setStats(res.data);
    } catch (ex) {
      console.log(ex.response);
    }
  }

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div className="row">
        
      <div className="col-md-4 col-xl-3">
        <div className="mini-stat clearfix bg-white">
          <span className="mini-stat-icon bg-purple mr-0 float-right">
            <i className="mdi mdi-basket"></i>
          </span>
          <div className="mini-stat-info">
            <span className="counter text-purple">{stats.orders_total}</span>
            Total Orders
          </div>
          <div className="clearfix"></div>
        </div>
      </div>

      <div className="col-md-4 col-xl-3">
        <div className="mini-stat clearfix bg-white">
          <span className="mini-stat-icon bg-brown mr-0 float-right">
            <i className="mdi mdi-account-multiple"></i>
          </span>
          <div className="mini-stat-info">
            <span className="counter text-brown">{stats.users_total}</span>
            Total Users
          </div>
        </div>
      </div>

      <div className="col-md-4 col-xl-3">
        <div className="mini-stat clearfix bg-white">
          <span className="mini-stat-icon bg-brown mr-0 float-right">
            <i className="mdi mdi-city"></i>
          </span>
          <div className="mini-stat-info">
            <span className="counter text-brown">{stats.locations_total}</span>
            Total Restaurants
          </div>
        </div>
      </div>


      <div className="col-12">
        <div className="card m-b-20">
          <div className="card-body">
            <h4 className="mt-0 m-b-30 header-title">Latest Orders</h4>

            <div className="table-responsive">
              <table className="table table-vertical mb-0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>User</th>
                    <th>Location</th>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Tip</th>
                    <th>Tax</th>
                    <th>Date</th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {stats.latest_orders && stats.latest_orders.map(order => 
                    <tr key={order.id}>
                      <td>#{order.id}</td>
                      <td> {order.first_name} {order.last_name}</td>
                      <td>{order.location && order.location.name}</td>
                      <td><span className={`badge badge-pill 
                       ${order.status === 'Paid' ? 'badge-success' : ''} 
                       ${order.status === 'Pending' ? 'badge-warning' : ''} 
                       ${order.status === 'Payment Error' ? 'badge-danger' : ''} 
                       ${order.status === 'Canceled' ? 'badge-secondary' : ''} 
                       ${order.status === 'Awaiting Payment' ? 'badge-info' : ''}`} >
                        {order.status}
                      </span></td>
                      <td>${order.total.toFixed(2)} </td>
                      <td>${order.tip.toFixed(2)} </td>
                      <td>${order.tax.toFixed(2)} </td>
                      <td>{order.created_at}</td>
                      {/* <td><button type="button" className="btn btn-secondary btn-sm waves-effect">Items</button></td> */}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Dashboard;