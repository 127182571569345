import React from 'react';
import PropTypes from 'prop-types';
import { MDBBtn } from 'mdbreact';
import Loading from '../../UI/Loading/Loading';
import Hour from './Hour/Hour.js';

function Hours(props) {

  const renderHours = type => {
    const hours = props.hours
      .filter(x => x.type === type)
      .map(hour => (
        <Hour {...hour} key={hour.uId} onDelete={props.onDelete} onUpdate={props.onUpdate} />
      ));

    return (
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th>Open</th>
            <th></th>
            <th>Close</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {hours}
        </tbody>
      </table>
    );
  }

  return props.loading ? <Loading /> : (
      <div className="row">
        <div className="col-12">
          <div className="card m-b-20">
            <div className="card-body">

              <h4 className="mt-0 header-title">Open/close hours</h4>
              <p className="text-muted m-b-30 font-14">Set open/close time for each type of service.</p>

              <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#dinein" role="tab">
                      <span className="d-none d-md-block">Dine in</span><span className="d-block d-md-none"><i className="mdi mdi-home-variant h5"></i></span>
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#pickup" role="tab">
                      <span className="d-none d-md-block">Pickup</span><span className="d-block d-md-none"><i className="mdi mdi-account h5"></i></span>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" data-toggle="tab" href="#delivery" role="tab">
                      <span className="d-none d-md-block">Delivery</span><span className="d-block d-md-none"><i className="mdi mdi-email h5"></i></span>
                  </a>
                </li> */}
              </ul>

              <div className="tab-content mt-3">
                <div className="tab-pane active" id="dinein" role="tabpanel">
                  {renderHours('DineIn')}
                  <MDBBtn color="info" size="sm" outline onClick={() => props.addHour('DineIn')}>Add hours</MDBBtn>
                </div>

                {/* functionality ready but not needed yet: */}
                {/* <div className="tab-pane" id="pickup" role="tabpanel">
                  {renderHours('Pickup')}
                  <MDBBtn color="info" size="sm" outline onClick={() => props.addHour('Pickup')}>Add hours</MDBBtn>
                </div>

                <div className="tab-pane" id="delivery" role="tabpanel">
                  {renderHours('Delivery')}
                  <MDBBtn color="info" size="sm" outline onClick={() => props.addHour('Delivery')}>Add hours</MDBBtn>
                </div> */}
              </div>

            </div>
          </div>
        </div>

        <div className="col-12 text-right">
          <MDBBtn color="success" onClick={props.onSubmit}>Save changes</MDBBtn>
        </div>
      </div>
    );
}

Hours.propTypes = {
  loading: PropTypes.bool.isRequired,
  hours: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  addHour: PropTypes.func.isRequired,
};

export default Hours;