import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';

function Preloader(props) {
  return (
    <Transition in={props.show} timeout={1500} mountOnEnter unmountOnExit>
      {state => (
        <div id="preloader" style={{ transition: 'all .5s linear', opacity: state === 'entered' ? 1 : 0 }}>
          <div id="status"><div className="spinner"></div></div>
        </div>
      )}
    </Transition>
  );
}

Preloader.propTypes = {
  show: PropTypes.bool
}

export default Preloader;