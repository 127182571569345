import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MDBBtn } from 'mdbreact';
import SortableList from '../../../hoc/SortableList/SortableList';
import Modal from '../../UI/Modal/Modal';
import ItemToolbar from '../Helpers/ItemToolbar/ItemToolbar';
import Category from './Category/Category';
import './Menu.css';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { deleteMenu, updateMenu, sortCategories, addCategory } from '../../../store/actions/menuBuilder/index';
import ShowInTimeRangeRedactor from '../Redactors/ShowInTimeRangeRedactor/ShowInTimeRangeRedactor';
import Redactors from '../Redactors/Redactors';
import RedactorsLegend from '../Redactors/RedactorsLegend/RedactorsLegend';
import MenuContext from '../../../context/menuContext';

function Menu(props) {
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [showDetails, setShowDetails] = useState(1);
  const [nameState, setNameState] = useState(props.name);

  const changeNameHandler = event => setNameState(event.target.value);
  const setVisibilityHandler = () => setShowDetails(!showDetails);

  const onSortEndHandler = ({ oldIndex, newIndex }) => {
    dispatch(sortCategories({
      menuId: props.id,
      oldIndex,
      newIndex,
      selectedItemId: props.categories[oldIndex].id,
      replacedItemId: props.categories[newIndex].id,
      successCallback: () => NotificationManager.success('Position updated')
    }));
  }; 
  const deleteMenuHandler = () => {
    if (!window.confirm('Do you want to remove the menu with all categories and items?')) return;

    dispatch(deleteMenu({
      id: props.id,
      successCallback: () => NotificationManager.success('Menu has been deleted')
    }));
  }
  const updateMenuHandler = () => {
    setSaving(true);
    dispatch(updateMenu({
      id: props.id,
      data: { name: nameState },
      successCallback: () => {
        NotificationManager.success('Saved');
        setSaving(false);
      }
    }));
  };
  const toggleActiveHandler = () => {
    dispatch(updateMenu({
      id: props.id,
      data: { active: !props.active },
      successCallback: () => NotificationManager.success('Saved')
    }));
  };
  const newCategoryHandler = () => {
    dispatch(addCategory({
      data: { menu_id: props.id, name: 'New category' },
      successCallback: () => NotificationManager.success('Category has been added')
    }));
  }


  const categories = props.categories.map(category => <Category key={category.id} {...category} />);
  const sortableCategories = <SortableList items={categories} onSortEnd={onSortEndHandler} lockAxis="y" useDragHandle />;

  return (
    <div>
      <MenuContext.Provider value={{ menuId: props.id }}>
        <div className={`mb_menu ${!props.active ? 'unactive' : ''}`}>
          <p className="mb_menu__name">{props.name}</p>
          
          <RedactorsLegend redactors={props.redactors} />

          <ItemToolbar
            editModalId={`modal-menu-${props.id}`}  
            deleteHandler={deleteMenuHandler}
            newHandler={newCategoryHandler}
            toggleDetailsHandler={setVisibilityHandler}
            activeHandler={toggleActiveHandler}
            detailsVisibility={showDetails}
            active={props.active}     
          >
          </ItemToolbar>
          
        </div>
        {showDetails ? (props.categories.length ? sortableCategories : 'No categories') : null}

        <Modal.body id={`modal-menu-${props.id}`} title="Edit menu"> 
          <label>Menu name</label>
          <input type="text" value={nameState} onChange={changeNameHandler}></input>
          <div className="text-right mt-2">
            {saving 
              ? <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...</button>
              : <button className="btn btn-success" onClick={updateMenuHandler}>Save</button>}
          </div>

          <Redactors 
            menuId={props.id}
            objectId={props.id} 
            objectType={'menu'}
            afterUpdate={updateMenuHandler}
            redactors={[
              { title: 'Show only in a range of time', component: ShowInTimeRangeRedactor }
            ]} />
        </Modal.body>
      </MenuContext.Provider>
    </div>
  );
}

export default Menu;