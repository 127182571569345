import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Dashboard from '../Pages/Dashboard/Dashboard'
import Login from '../Pages/Auth/Login'
import Restaurants from '../Pages/Restaurants/Restaurants';
import Restaurant from '../Pages/Restaurants/Restaurant';
import MenuBuilder from '../MenuBuilder/MenuBuilder';
import RestaurantHours from '../Pages/Restaurants/RestaurantHours';

function ContentBuilder(props) {
  const protectedRoutes = (
    <Switch>
      <Route path="/restaurants/:id/hours" component={RestaurantHours} />
      <Route path="/restaurants/:id/menu_builder" component={MenuBuilder} />
      <Route path="/restaurants/:id/edit" render={(props) => <Restaurant editMode="1" {...props} />} />
      <Route path="/restaurants/create" component={Restaurant} />
      <Route path="/restaurants" component={Restaurants} />
      <Route path="/" component={Dashboard} />
    </Switch>
  );

  return (
    <Switch>
      {props.authenticated ? protectedRoutes : <Route path="/" component={Login} />}
    </Switch>
  );
}

const mapSateToProps = state => ({
  authenticated: state.auth.authenticated
});

export default connect(mapSateToProps)(ContentBuilder);