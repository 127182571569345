import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Item.css';
import Variation from '../Variation/Variation';
import ItemToolbar from '../../Helpers/ItemToolbar/ItemToolbar';
import Modal from '../../../UI/Modal/Modal';
import Image from '../../Helpers/Image/Image';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { updateItem, deleteItemImage, deleteItem, addVariation } from '../../../../store/actions/menuBuilder/index';
import ShowInTimeRangeRedactor from '../../Redactors/ShowInTimeRangeRedactor/ShowInTimeRangeRedactor';
import Redactors from '../../Redactors/Redactors';
import RedactorsLegend from '../../Redactors/RedactorsLegend/RedactorsLegend';

const Item = props => {
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [detailsState, setDetailsState] = useState(0);
  const [nameState, setNameState] = useState(props.name);
  const [descriptionState, setDescriptionState] = useState(props.description || '');
  const [hideDescription, setHideDescription] = useState(props.hide_description_on_menu || 0);
  const [imageState, setImageState] = useState(null);
  const [isAlcohol, setIsAlcohol] = useState(props.is_alcohol || 0);

  const setDetailsHandler = () => setDetailsState(!detailsState);
  const changeNameHandler = event => setNameState(event.target.value);
  const changeDescriptionHandler = event => setDescriptionState(event.target.value);
  const changeImageHandler = event => setImageState(event.target.files[0]);
  const changeHideDescriptionHandler = event => setHideDescription(event.target.checked ? 1 : 0);
  const changeIsAlcoholHandler = event => setIsAlcohol(event.target.checked ? 1 : 0);

  const newVariationHandler = () => {
    dispatch(addVariation({
      data: {
        size: 'New variation',
        price: 0,
        item_id: props.id
      },
      successCallback: () => NotificationManager.success('Variation has been added')
    }));
    setDetailsState(1);
  }
  const updateItemHandler = () => {
    setSaving(true);
    const data = new FormData();
    data.append('name', nameState);
    data.append('description', descriptionState);
    data.append('hide_description_on_menu', hideDescription);
    data.append('is_alcohol', isAlcohol);
    if (imageState) data.append('image', imageState, imageState.name);
      
    dispatch(updateItem({
      id: props.id,
      data,
      headers: { 'content-type': 'multipart/form-data' },
      successCallback: () => {
        NotificationManager.success('Saved');
        setSaving(false);
      }
    }));
  }
  const toggleActiveHandler = () => {
    dispatch(updateItem({
      id: props.id,
      data:  { active: !props.active },
      successCallback: () => NotificationManager.success('Saved')
    }));
  }
  const deleteItemHandler = () => {
    if (!window.confirm('Do you want to remove the item with all variations?')) return;

    dispatch(deleteItem({
      id: props.id,
      successCallback: () => NotificationManager.success('Item has been deleted')
    }));
  }
  const deleteImageHandler = () => {
    dispatch(deleteItemImage({
      id: props.id,
      successCallback: () => NotificationManager.success('Image has been deleted')
    }));
    setImageState(null);
  }


  const variations = props.variations.map(variation => <Variation key={variation.id} {...variation} />);

  return (
    <div>
      <div className="mb_item">
        <div className="mb_item__details">
          <p className="mb_item__name">
            {props.name}
            {props.image_url ? <img alt="" src={props.image_url} className="mb_subcategory_image" style={{ height: '30px', marginLeft: '20px', borderRadius: '5px' }} /> : null}  
          </p>
          <div className="d-flex align-items-center">
            <RedactorsLegend redactors={props.redactors} />
            {props.is_alcohol ? <i className="mbi ion-wineglass text-secondary ml-2" title="Is alcohol" /> : null}
          </div>
          <p className="mb_item__description">{props.description}</p>
        </div>

        <ItemToolbar
          editModalId={`modal-item-${props.id}`} 
          deleteHandler={deleteItemHandler}
          newHandler={newVariationHandler}
          toggleDetailsHandler={setDetailsHandler}
          activeHandler={toggleActiveHandler}
          detailsVisibility={detailsState}
          active={props.active}
        />
      </div>

      {detailsState ? variations : null}

      <Modal.body id={`modal-item-${props.id}`} title="Edit item" >
         <label>Name</label>
         <input type="text" defaultValue={nameState} onChange={changeNameHandler}/>
         <label>Description</label>
         <input type="text" defaultValue={descriptionState} onChange={changeDescriptionHandler}/>
         <label>
          <input type="checkbox" defaultChecked={isAlcohol} onChange={changeIsAlcoholHandler} style={{width: 'auto', marginRight: '10px'}}/>
          Alcohol product
         </label>
         <label>
          <input type="checkbox" defaultChecked={hideDescription} onChange={changeHideDescriptionHandler} style={{width: 'auto', marginRight: '10px'}}/>
          Hide description on menu
          </label>
         <label>Image</label>
         <Image url={props.image_url} onDelete={deleteImageHandler} onChange={changeImageHandler}/>

          <div className="text-right mt-2">
          {saving 
            ? <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...</button>
            : <button className="btn btn-success" onClick={updateItemHandler}>Save</button>}
          </div>

         <Redactors 
          objectId={props.id} 
          objectType={'item'}
          afterUpdate={updateItemHandler}
          redactors={[
            { title: 'Show only in a range of time', component: ShowInTimeRangeRedactor }
          ]} />
      </Modal.body>
    </div>
  )
}


export default Item;