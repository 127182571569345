import axios from '../../../axios';

export const sort = (entityName, oldIndex, newIndex, selectedItemId, replacedItemId, successCallback, parentId = null) => {
  if (oldIndex === newIndex) return;

  const sortingData = {
    type: oldIndex < newIndex ? 'moveAfter' : 'moveBefore', // 'moveAfter','moveBefore',
    entityName, // eg: 'categories', 'items'
    id: selectedItemId, // item ID
    positionEntityId: replacedItemId, // ID of item to change with
  };
  if (parentId) sortingData.parentId = parentId; // parent entity id (only if many to many DB relation)

  axios.post(`/sort`, sortingData)
    .then(res => successCallback());
}