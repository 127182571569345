import React, { Component } from "react";
import { connect } from 'react-redux';
import DashboardIndex from '../../../components/Dashboard/Dashboard';
import * as actionTypes from '../../../store/actions/actionTypes';

class Dashboard extends Component {
  componentDidMount() {
    this.props.changePageTitle('Dashboard');
  }

  render() {
    return <DashboardIndex />;
  }
}


const mapDispatchToProps = dispatch => ({
  changePageTitle: (title) => dispatch({ type: actionTypes.UI_CHANGE_PAGE_TITLE, title })
});

export default connect(null, mapDispatchToProps)(Dashboard);
