import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { MDBBtn } from 'mdbreact';
import { v4 as uuidv4 } from 'uuid';
import axios from '../../../../axios';
import Loading from '../../../UI/Loading/Loading';

export default function ShowInRangeOfTime(props) {
  const REDACTOR = 'show_in_time_range';
  const [loading, setLoading] = useState(true);
  const [enabled, setEnabled] = useState(true);
  const [hours, setHours] = useState([]);

  const add = () => {
    const newHour = {
      id: uuidv4(),
      start_day: '1',
      start_hour: '06:00',
      end_day: '1',
      end_hour: '18:00',
      enabled: enabled
    };
    setHours([...hours, newHour]);
  }
  const edit = (id, field, value) => {
    const newHours = [...hours];
    const hour = newHours.find(x => x.id === id);

    // auto change "to" time
    if (field === 'start_day' && +value > +hour.end_day) {
      hour.end_day = value;
    }
    if (field === 'start_hour' && hour.end_day === hour.start_day) {
      hour.end_hour = value;
    }

    // validate 
    if (field === 'end_day') {
      if (value < hour.start_day) {
        return NotificationManager.warning('Day "To" cannot be earlier than day "From"');
      }
    }
    if (field === 'end_hour' && hour.end_day === hour.start_day) {
      if (value < hour.start_hour) {
        return NotificationManager.warning('Hour "To" cannot be earlier than hour "From" in the same day');
      }
    }

    hour[field] = value;
    setHours(newHours);
  }
  const remove = (id) => {
    setHours(hours.filter(x => x.id !== id));
  }
  const fetchRedactors = async () => {
    try {
      const res = await axios.get(`/menu_builder/redactors/${props.objectType}/${props.objectId}/${REDACTOR}`);
      setHours(res.data);
      setEnabled(res.data.length ? res.data[0].enabled : 0);
      setLoading(false);
    } catch (ex) {
      NotificationManager.error('Something went wrong when fetching data');
      console.log(ex.reponse)
    }
  }
  const submit = async () => {
    try {
      const data = { 
        redactors: hours, 
        menu_id: props.menuId
      };
      await axios.post(`/menu_builder/redactors/${props.objectType}/${props.objectId}/${REDACTOR}`, data);
      props.afterUpdate();
    } catch (ex) {
      NotificationManager.error('Something went wrong');
      console.log(ex.reponse);
    }
  }
  const toggleEnable = () => {
    const newEnabledValue = !enabled;
    const newHours = hours.map(x => {
      x.enabled = newEnabledValue;
      return x;
    });
    
    setEnabled(newEnabledValue);
    setHours(newHours);
  }

  useEffect(() => {
    fetchRedactors();
  }, []);

  return loading ? <Loading /> : (
    <div>

      <div className="d-flex align-items-center">
        <div>
          <input type="checkbox" id="switch3" switch="bool" checked={enabled ? "checked" : ""}  onChange={toggleEnable}  />
          <label htmlFor="switch3" data-on-label="Yes" data-off-label="No"></label>
        </div>
        <h4 className="mt-0 header-title ml-3">Enabled</h4>
      </div>
      <p className="text-muted m-b-30 font-14">If disabled items are visible all the time.</p>
      
      {enabled ? (
        <div className="tab-pane active" id="dinein" role="tabpanel">
          <table className="table table-bordered table-sm">
            <thead className="thead-light">
              <tr>
                <th>From</th>
                <th>To</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {hours.map(hour =>
                <tr key={hour.id}>
                  <td className="pt-2 pb-0">
                    <div className="row">
                      <div className="col-md-6">
                        <select className="form-control" value={hour.start_day} onChange={(e) => edit(hour.id, 'start_day', e.target.value)} >
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                            <option value="7">Sunday</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <input className="form-control" type="time" value={hour.start_hour} onChange={(e) => edit(hour.id, 'start_hour', e.target.value)} />
                      </div>
                    </div>
                  </td>
                  <td className="pt-2 pb-0">
                    <div className="row">
                      <div className="col-md-6">
                        <select className="form-control" value={hour.end_day} onChange={(e) => edit(hour.id, 'end_day', e.target.value)} >
                            <option value="1">Monday</option>
                            <option value="2">Tuesday</option>
                            <option value="3">Wednesday</option>
                            <option value="4">Thursday</option>
                            <option value="5">Friday</option>
                            <option value="6">Saturday</option>
                            <option value="7">Sunday</option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <input className="form-control" type="time" value={hour.end_hour} onChange={(e) => edit(hour.id, 'end_hour', e.target.value)}/>
                      </div>
                    </div>
                  </td>
            
                  <td className="pt-1 pb-0">
                    <MDBBtn size="lg" onClick={() => remove(hour.id)}><i className="fa fa-trash"></i></MDBBtn>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <MDBBtn color="info" size="sm" onClick={add}>Add time range</MDBBtn>
        </div>
      ) : null}

      <div className="text-right mt-2">
        <MDBBtn color="success" onClick={submit}>Save</MDBBtn>
      </div>

    </div>
  );
}