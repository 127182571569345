import React, { useContext } from 'react';
import { Route, Switch, NavLink , useRouteMatch  } from 'react-router-dom';
import styles from './Redactors.module.css';
import MenuContext from '../../../context/menuContext';

export default function Redactors(props) {
  const menuContext = useContext(MenuContext);
  const { url, path } = useRouteMatch();

  return (
    <div className="card mt-4">
      <h5 className="card-header mt-0">Additional Options</h5>
      <nav>
        <ul className={styles.list}>
          {props.redactors.map((redactor, index) => 
            <li key={index}>
              <NavLink to={`${url}/redactors-${props.objectType}-${props.objectId}`} activeClassName={styles.activeItem}>
                {redactor.title}
              </NavLink>
            </li>
          )}
        </ul>
      </nav>
      
      <div className="card-body">
        {props.redactors.map((Redactor, index) => 
          <Route key={index} path={`${path}/redactors-${props.objectType}-${props.objectId}`}>
            <Redactor.component 
              menuId={menuContext.menuId}
              afterUpdate={props.afterUpdate}
              objectId={props.objectId} 
              objectType={props.objectType} />
          </Route>
        )}
      </div>
    </div>
  );
}