import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';

function Header(props) {
  const [dropdownOpenprofile, setDropdownOpenprofile] = useState(false);

  const togglesFullSreen = e => {
    if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
      if (document.documentElement.requestFullscreen) 
        document.documentElement.requestFullscreen();
      else if (document.documentElement.mozRequestFullScreen) 
        document.documentElement.mozRequestFullScreen();
      else if (document.documentElement.webkitRequestFullscreen) 
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    } else {
      if (document.cancelFullScreen) 
        document.cancelFullScreen();
      else if (document.mozCancelFullScreen) 
        document.mozCancelFullScreen();
      else if (document.webkitCancelFullScreen) 
        document.webkitCancelFullScreen();
    }
  }

  const history = useHistory();
  const logoutHandler = () => {
    history.push('/');
    props.onLogout();
  }

  return (
    <div className="topbar">
      <nav className="navbar-custom">


        <div className="search-wrap" id="search-wrap">
          <div className="search-bar">
            <input className="search-input" type="search" placeholder="Search" />
            <a href="#" className="close-search toggle-search" data-target="#search-wrap">
              <i className="mdi mdi-close-circle"></i>
            </a>
          </div>
        </div>


        <ul className="list-inline float-right mb-0">

          {/* <li className="list-inline-item dropdown notification-list">
            <Link  className="nav-link waves-effect toggle-search" to="#"  data-target="#search-wrap">
              <i className="mdi mdi-magnify noti-icon"></i>
            </Link>
          </li> */}

          <li className="list-inline-item dropdown notification-list hidden-xs-down">
            <Link  onClick={togglesFullSreen}  className="nav-link waves-effect" to="#" id="btn-fullscreen">
              <i className="mdi mdi-fullscreen noti-icon"></i>
            </Link>
          </li>

          <li className="list-inline-item dropdown notification-list">
            <Dropdown isOpen={dropdownOpenprofile}  toggle={() => setDropdownOpenprofile(!dropdownOpenprofile)}>
              <DropdownToggle className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user" tag="a">
                <img src="/assets/images/users/avatar-4.jpg" alt="user" className="rounded-circle" />
              </DropdownToggle>
              <DropdownMenu>
                {/* <DropdownItem><i className="mdi mdi-account-circle m-r-5"></i> Profile</DropdownItem> */}
                {/* <DropdownItem><i className="mdi mdi-wallet m-r-5"></i> My Wallet</DropdownItem> */}
                {/* <DropdownItem><span className="badge badge-success float-right">11</span><i className="mdi mdi-settings m-r-5"></i> Settings</DropdownItem> */}
                {/* <DropdownItem><i className="mdi mdi-lock-open-outline m-r-5"></i> Lock screen</DropdownItem> */}
                <DropdownItem onClick={logoutHandler}><i className="mdi mdi-power text-danger"></i> Logout</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>

        </ul>


        <ul className="list-inline menu-left mb-0">

          <li className="list-inline-item">
            <button type="button" className="button-menu-mobile open-left waves-effect">
              <i className="ion-navicon"></i>
            </button>
          </li>

          <li className="hide-phone list-inline-item app-search">
            <h3 className="page-title" id="now_routing">{props.pageTitle}</h3>
          </li>

        </ul>


        <div className="clearfix"></div>


      </nav>
    </div>
  );
}

Header.propTypes = {
  pageTitle: PropTypes.string,
  onLogout: PropTypes.func
};

export default Header;
