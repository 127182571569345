import React from 'react';
import PropTypes from 'prop-types';


const ShowInTimeRage = (props) => {
  return (
    <i className="mbi ion-android-clock text-secondary" 
      title={`Show only in a range of time ${props.enabled ? '(enabled)' : '(disabled)'}`} />
  );
}

const propTypes = {
  redactors: PropTypes.array
};

const defaultProps = {
  redactors: []
};

function RedactorsLegend(props) {
  const redactorsUnique = [];
  props.redactors.forEach(redactor => {
    if (redactorsUnique.findIndex(x => x.redactor === redactor.redactor) === -1) {
      redactorsUnique.push(redactor);
    }
  });

  return (
    <div>
      {redactorsUnique.map(redactor => {
        if (redactor.enabled) {
          switch (redactor.redactor) {
            case 'show_in_time_range': return <ShowInTimeRage key={redactor.id} {...redactor} />
            default: return null;
          }
        }
        return null;
      })}
    </div>
  );
}

RedactorsLegend.propTypes = propTypes;
RedactorsLegend.defaultProps = defaultProps;

export default RedactorsLegend;