import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment-timezone';

function TimezoneInput(props) {
  const timezones = moment.tz.names().map(x => ({ label: x, value: x }));

  const selectedOption = () => {
    const option = timezones.find(x => x.value === props.value);
    return option;
  }

  const onChange = selectedOption => {
    props.onChange(selectedOption.value);
  }
  
  return (
    <Select
      value={selectedOption()}
      onChange={onChange}
      options={timezones}
      />
  );
}

TimezoneInput.protoTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
};

export default TimezoneInput;