import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './Variation.css';
import ModifierGroup from '../ModifierGroup/ModifierGroup';
import ItemToolbar from '../../Helpers/ItemToolbar/ItemToolbar';
import Modal from '../../../UI/Modal/Modal';
import Image from '../../Helpers/Image/Image';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { updateVariation, deleteVariationImage, deleteVariation, sortVariationModifiers } from '../../../../store/actions/menuBuilder/index';
import SortableList from '../../../../hoc/SortableList/SortableList';
import ShowInTimeRangeRedactor from '../../Redactors/ShowInTimeRangeRedactor/ShowInTimeRangeRedactor';
import Redactors from '../../Redactors/Redactors';
import RedactorsLegend from '../../Redactors/RedactorsLegend/RedactorsLegend';

const Variation = props => {
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [nameState, setNameState] = useState(props.size);
  const [priceState, setPriceState] = useState(props.price);
  const [imageState, setImageState] = useState(null);
  const [groupsState, setGroupsState] = useState(props.modifier_groups.map(x => parseInt(x.id)));

  const changeNameHandler = event => setNameState(event.target.value);
  const changePriceHandler = event => setPriceState(event.target.value);
  const changeImageHandler = event => setImageState(event.target.files[0]);

  const changeGroupHandler = event => {
    const isChecked = event.target.checked;
    const groupId = parseInt(event.target.value);
      
    if (isChecked) {
      const tags = [].concat(groupsState, groupId);
      setGroupsState(tags);
    } else {
      const tags = groupsState.filter(x => x !== groupId);
      setGroupsState(tags);
    }
  }
  const updateVariationHandler = () => {
    setSaving(true);
    const data = new FormData();
    data.append('size', nameState);
    data.append('price', priceState);
    data.append('modifierGroups', groupsState);
    if (imageState) data.append('image', imageState, imageState.name);

    dispatch(updateVariation({
      id: props.id,
      data,
      headers: { 'content-type': 'multipart/form-data' },
      successCallback: () => {
        NotificationManager.success('Saved');
        setSaving(false);
      }
    }));
  }
  const toggleActiveHandler = () => {
    dispatch(updateVariation({
      id: props.id,
      data: { active: !props.active },
      successCallback: () => NotificationManager.success('Saved')
    }));
  }
  const deleteVariationHandler = () => {
    if (!window.confirm('Do you want to remove the variation?')) return;

    dispatch(deleteVariation({
      id: props.id,
      successCallback: () => NotificationManager.success('Variation has been deleted')
    }));
  }
  const deleteImageHandler = () => {
    dispatch(deleteVariationImage({
      id: props.id,
      successCallback: () => NotificationManager.success('Image has been deleted')
    }));
    setImageState(null);
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(sortVariationModifiers({
      variationId: props.id,
      oldIndex,
      newIndex,
      selectedItemId: props.modifier_groups[oldIndex].id,
      replacedItemId: props.modifier_groups[newIndex].id,
      successCallback: () => NotificationManager.success('Saved')
    }));
  }


  const modifierGroups = props.modifier_groups.map(modifierGroup => <ModifierGroup hideDragHandle key={modifierGroup.id} {...modifierGroup}/>);
  const sortableModifierGroups = <SortableList items={modifierGroups} onSortEnd={onSortEnd} />;
  
  const availableModifiersGroups = useSelector(state => state.menuBuilder.modifiersGroups);
  const availableGroups = availableModifiersGroups.map(group => (
    <label key={group.id}>
      {group.name}
      <input type="checkbox" value={group.id} onChange={changeGroupHandler} defaultChecked={groupsState.indexOf(group.id) !== -1}/>
    </label>
  ));

  return (
    <div>
      <div className="mb_variation">
        <p className="mb_variation__name">{props.size} <span className="mb_variation__price">${props.price}</span></p>
        <RedactorsLegend redactors={props.redactors} />
        {props.image_url ? <img alt="" src={props.image_url} className="mb_subcategory_image" style={{ height: '30px', marginLeft: '20px', borderRadius: '5px' }} /> : null}  

        <ItemToolbar
          editModalId={`modal-variation-${props.id}`} 
          deleteHandler={deleteVariationHandler}
          activeHandler={toggleActiveHandler}
          active={props.active}
        />

        <div className="mb_variation_groups">
          {sortableModifierGroups}
        </div>
      </div>

      <Modal.body id={`modal-variation-${props.id}`} title="Edit variation" >
          <label>Size</label>
          <input type="text" defaultValue={nameState} onChange={changeNameHandler}/>
          <label>Price</label>
          <input type="text" defaultValue={priceState} onChange={changePriceHandler}/>
          <label>Image</label>
          <Image url={props.image_url} onDelete={deleteImageHandler} onChange={changeImageHandler}/>

          <label>Modifiers Groups</label>
          <div className="mb_variation_modal_groups">
            {availableGroups}
          </div>

          <div className="text-right mt-2">
            {saving 
              ? <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...</button>
              : <button className="btn btn-success" onClick={updateVariationHandler}>Save</button>}
          </div>

          <Redactors 
            objectId={props.id} 
            objectType={'variation'}
            afterUpdate={updateVariationHandler}
            redactors={[
              { title: 'Show only in a range of time', component: ShowInTimeRangeRedactor }
            ]} />
      </Modal.body>
    </div>
  );
};

export default Variation;