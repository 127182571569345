// AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const AUTHENTICATE = 'AUTHENTICATE';


// MENU BUILDER
export const MENU_BUILDER_SET_LOCATION_ID = 'MENU_BUILDER_SET_LOCATION_ID';

export const MENU_BUILDER_SET_MENUS = 'MENU_BUILDER_SET_MENUS';
export const MENU_BUILDER_SET_MODIFIER_GROUPS = 'MENU_BUILDER_SET_MODIFIER_GROUPS';

export const MENU_BUILDER_SORT_CATEGORIES = 'MENU_BUILDER_SORT_CATEGORIES';
export const MENU_BUILDER_SORT_SUBCATEGORIES = 'MENU_BUILDER_SORT_SUBCATEGORIES';
export const MENU_BUILDER_SORT_ITEMS = 'MENU_BUILDER_SORT_ITEMS';
export const MENU_BUILDER_SORT_MODIFIERS = 'MENU_BUILDER_SORT_MODIFIERS';
export const MENU_BUILDER_SORT_VARIATION_MODIFIERS = 'MENU_BUILDER_SORT_VARIATION_MODIFIERS';


// UI
export const UI_PRELOADER_HIDE = 'UI_PRELOADER_HIDE';
export const UI_CHANGE_PAGE_TITLE = 'UI_CHANGE_PAGE_TITLE';