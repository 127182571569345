import React from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setPageTitle } from '../../store/actions';
import { getMenus, getModifierGroups, setLocationId } from '../../store/actions/menuBuilder/index'
import MenuBuilderComponent from '../../components/MenuBuilder/MenuBuilder';

class MenuBuilder extends React.Component {
  state = {
    loading: true
  };
  restaurantId = +this.props.match.params.id

  componentDidMount() {
    this.props.changePageTitle('Restaurant menus');
    this.props.setLocationId(this.restaurantId);

    this.props.getMenus();
    this.props.getModifierGroups();
    this.setState({ loading: false });
  }

  render() {
    return (
      <MenuBuilderComponent 
        loading={this.state.loading}
        restaurantId={this.restaurantId}
        menus={this.props.menus}
        modifiersGroups={this.props.modifiersGroups}
      />
    );
  }
}

const mapStateToProps = state => ({
  menus: state.menuBuilder.menus,
  modifiersGroups: state.menuBuilder.modifiersGroups
});

const mapDispatchToProps = dispatch => ({
  changePageTitle: title => dispatch(setPageTitle(title)),
  setLocationId: id => dispatch(setLocationId(id)),
  getMenus: () => dispatch(getMenus()),
  getModifierGroups: () => dispatch(getModifierGroups()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuBuilder));