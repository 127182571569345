import * as actionTypes from '../actionTypes';
import axios from '../../../axios';
import { getMenus } from './menus';
import { sort } from './_utils';

const setSortCategories = (menuId, oldIndex, newIndex) => {
  return {
    type: actionTypes.MENU_BUILDER_SORT_CATEGORIES,
    menuId,
    oldIndex,
    newIndex
  }
}

// PUBLIC CLASSES 

export const sortCategories = ({ menuId, oldIndex, newIndex, selectedItemId, replacedItemId, successCallback }) => {
  return dispatch => {
    sort('categories', oldIndex, newIndex, selectedItemId, replacedItemId, successCallback)
    dispatch(setSortCategories(menuId, oldIndex, newIndex));
  }
}

export const addCategory = ({ data, successCallback }) => {
  return dispatch => {
    axios.post(`/menu_builder/categories`, data)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}

export const updateCategory = ({ id, data, successCallback }) => {
  return dispatch => {
    axios.patch(`/menu_builder/categories/${id}`, data)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}
export const deleteCategory = ({ id, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/categories/${id}`)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}