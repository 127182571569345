import React from 'react';
import PropTypes from 'prop-types';
import { MDBBtn } from 'mdbreact';
import Loading from '../UI/Loading/Loading';
import './MenuBuilder.css'
import ModifierGroups from './ModifierGroups/ModifierGroups';
import Menu from './Menu/Menu';
import { useDispatch } from 'react-redux';
import { addMenu } from '../../store/actions/menuBuilder/index';
import { NotificationManager } from 'react-notifications';

function MenuBuilder(props) {
  const dispatch = useDispatch();

  const addMenuHandler = () => {
    dispatch(addMenu({
      data: {
        name: 'Menu #' + (props.menus.length + 1),
        location_id: props.restaurantId
      },
      successCallback: () => NotificationManager.success('Menu has been added')
    }));
  }

  const menus = props.menus.map(menu => (
    // <tr key={menu.id}>
    //   <td>{menu.name}</td>
    //   <td style={{ width: '200px' }}>
    //     <MDBBtn color="primary" size="sm" className="ml-2" onClick={addMenuHandler}>Hours</MDBBtn>
    //     <MDBBtn color="warning" size="sm" className="ml-2" onClick={addMenuHandler}>Edit</MDBBtn>
    //     <MDBBtn color="danger" size="sm" className="ml-2" onClick={addMenuHandler}>Delete</MDBBtn>
    //   </td>
      <Menu key={menu.id} {...menu} />
    // </tr> 
  ));

  return (
    <div className="row">
      <div className="col mb_container">
        <div className="card m-b-20">
          <div className="card-body">
  
            <h4 className="mt-0 header-title">Menu builder</h4>
            <p className="text-muted m-b-30 font-14">Select a menu to edit or create new.</p>

            
            <div className="mb_tools">
              <MDBBtn color="success" onClick={addMenuHandler}>Add new menu</MDBBtn>

              <ModifierGroups modifiersGroups={props.modifiersGroups} />
            </div>  

            {props.loading ? <Loading /> : menus}

            {/* <table className="table table-hover">
              <thead className="thead-light">
                <tr>
                  <th>Menu</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {menus}
              </tbody>
            </table> */}
 
          </div>
        </div>
      </div>
    </div>
  );
}

MenuBuilder.propTypes = {
  menus: PropTypes.array,
  modifiersGroups: PropTypes.array,
  loading: PropTypes.bool,
  restaurantId: PropTypes.number,
};

export default MenuBuilder;