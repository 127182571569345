import * as actionTypes from '../actionTypes';
import axios from '../../../axios';

const setMenus = menus => {
  return {
    type: actionTypes.MENU_BUILDER_SET_MENUS,
    menus
  };
}


// PUBLIC CLASSES

export const getMenus = () => {
  return (dispatch, getState) => {
    const locationId = getState().menuBuilder.locationId

    axios.get(`/locations/${locationId}/menus`)
      .then(res => dispatch(setMenus(res.data)));
  }
}

export const addMenu = ({ data, successCallback }) => {
  return dispatch => {
    axios.post(`/menu_builder/menus`, data)
     .then(res => {
        successCallback();
        dispatch(getMenus());
      })
  }
}

export const updateMenu = ({ id, data, successCallback }) => {
  return dispatch => {
    axios.patch(`/menu_builder/menus/${id}`, data)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}

export const deleteMenu = ({ id, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/menus/${id}`)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}