import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MDBBtn } from 'mdbreact';
import Modal from '../../UI/Modal/Modal';
import Group from './Group/Group';
import { useDispatch } from 'react-redux';
import { addModifierGroup } from '../../../store/actions/menuBuilder/index';
import { NotificationManager } from 'react-notifications';

function ModifierGroups(props) {
  const dispatch = useDispatch();
  const [term, setTerm] = useState('');
  const [items, setItems] = useState([]);

  const newGroupHandler = () => {
    dispatch(addModifierGroup({
      name: 'New modifiers group',
      type: 'checkbox',
      successCallback: () => NotificationManager.success('Group has been added')
    }));
  }

  const search = e => {
    const value = e.target.value;
    setTerm(value);
    // filter items
    const filteredItems = props.modifiersGroups.filter(group => group.name.toLowerCase().match(value.toLowerCase()))
    setItems(filteredItems);
  }

  useEffect(() => {
    setItems(props.modifiersGroups);
  }, [props.modifiersGroups])

  return (
    <span>
      <Modal.body id="modal-mg" title="Modifiers">
        <MDBBtn color="success" onClick={newGroupHandler}>Add new group</MDBBtn>
        <input 
          type="text" 
          placeholder="Search..." 
          value={term}
          onChange={search}
          className="form-control my-3" /> 
        <div className="mt-3">
          {items.map(group => <Group key={group.id} {...group} />)}
        </div>
      </Modal.body>

      <Modal.button modalId="modal-mg">Modifiers</Modal.button>
    </span>
  );
}
           

ModifierGroups.propTypes = {
  modifiersGroups: PropTypes.array,
};

export default ModifierGroups;