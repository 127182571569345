import React from 'react';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import * as styles from './SortableList.module.css';

const DragHandle = sortableHandle(() => (
  <span className={styles.dragIcon}>
    <i className="fa fa-bars"></i>
  </span>
));

const SortableItem = sortableElement(({ item }) => {
  return (
    <li className={styles.item}>
      {!item.props.hideDragHandle ? <DragHandle /> : null}
      <div className={styles.itemValue}>
        {item}
      </div>
    </li>
  );
});

const SortableList = sortableContainer(({ items }) => {
  return (
    <ul className={styles.list}>
      {items.map((item, index) => (
        <SortableItem key={`item-${item.key}`} index={index} item={item} />
      ))}
    </ul>
  );
});

export default SortableList;


// Elements have to own "position" props.
//
// example usage:
//
//
// import SortableList from '../SortableList';
//
// const onSortEnd = ({oldIndex, newIndex}) => {
//   if (oldIndex === newIndex) return;
//   console.log('[Sort end]')
//   console.log(oldIndex, newIndex)
// };
// const items = elements.map(el => <Element key=""/>);
//
// <SortableList items={item} onSortEnd={onSortEnd} useDragHandle />;