import { combineReducers } from 'redux';
import authReducer from './reducers/auth';
import uiReducer from './reducers/ui';
import menuBuilderReducer from './reducers/menuBuilder';

export default combineReducers({
  ui: uiReducer,
  auth: authReducer,
  menuBuilder: menuBuilderReducer
});
