import React, { Component } from 'react';
import axios from '../../../axios';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions/actionTypes';
import RestaurantsList from '../../../components/Restaurants/RestaurantsList';

class Restaurants extends Component {
  state = {
    locations: [],
    loading: true
  };

  componentDidMount() {
    this.fetchRestaurants();
    this.props.changePageTitle('Restaurants');
  }

  fetchRestaurants() {
    axios.get(`/locations-all`)
      .then(res => {
        console.log(res.data)
        this.setState({ locations: res.data });
        this.setState({ loading: false });
      });
  }

  render() {
    return <RestaurantsList loading={this.state.loading} locations={this.state.locations} />;
  }
}

const mapDispatchToProps = dispatch => ({
  changePageTitle: (title) => dispatch({ type: actionTypes.UI_CHANGE_PAGE_TITLE, title })
});

export default connect(null, mapDispatchToProps)(Restaurants);