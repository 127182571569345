import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Modifier.css';
import Modal from '../../../../components/UI/Modal/Modal';
import Image from '../../Helpers/Image/Image';
import ItemToolbar from '../../Helpers/ItemToolbar/ItemToolbar';
import { useDispatch } from 'react-redux';
import { updateModifier, deleteModifier, deleteModifierImage } from '../../../../store/actions/menuBuilder/index';
import { NotificationManager } from 'react-notifications';

const Modifier = props => {
  const dispatch = useDispatch();

  const [nameState, setNameState] = useState(props.name);
  const [descriptionState, setDescriptionState] = useState(props.description || '');
  const [priceState, setPriceState] = useState(props.price);
  const [imageState, setImageState] = useState(null);

  const changeNameHandler = event => setNameState(event.target.value);
  const changeDescriptionHandler = event => setDescriptionState(event.target.value);
  const changePriceHandler = event => setPriceState(event.target.value);
  const changeImageHandler = event => setImageState(event.target.files[0]);

  const deleteModifierHandler = () => {
    if (!window.confirm('Do you want to remove the modifier?')) return

    dispatch(deleteModifier({
      id: props.id,
      successCallback: () => NotificationManager.success('Modifier has been deleted')
    }));
  }
  const toggleActiveHandler = () => {
    dispatch(updateModifier({
      id: props.id,
      data: { active: !props.active },
      successCallback: () => NotificationManager.success('Saved') 
    }));
  }
  const deleteImageHandler = () => {
    dispatch(deleteModifierImage({
      id: props.id,
      successCallback: () => NotificationManager.success('Image has been deleted') 
    }));
    setImageState(null);
  }
  const updateModifierHandler = () => {
    const data = new FormData();
    data.append('name', nameState);
    data.append('description', descriptionState);
    data.append('price', priceState);
    if (imageState) data.append('image', imageState, imageState.name);

    dispatch(updateModifier({
      id: props.id,
      data,
      headers: { 'content-type': 'multipart/form-data' },
      successCallback: () => NotificationManager.success('Saved') 
    }));
  }


  return (
    <div className="mb_modifier_details">
      <div className="mb_modifier_details__info">
        <p>
          <span className="mb_modifier_details__name">{props.name}</span> ${props.price}
          {props.image_url ? <img alt="" src={props.image_url} className="mb_subcategory_image" style={{ height: '30px', marginLeft: '20px', borderRadius: '5px' }} /> : null}  
        </p>
        {props.description ? <p className="mb_modifier_details__description">{props.description}</p> : null}
      </div>

      <ItemToolbar
        editModalId={`modal-modifier-${props.id}`} 
        deleteHandler={deleteModifierHandler}
        activeHandler={toggleActiveHandler}
        active={props.active}
      />

      <Modal.body id={`modal-modifier-${props.id}`} title="Edit modifier" save={updateModifierHandler}>
          <label>Name</label>
          <input type="text" value={nameState} onChange={changeNameHandler}></input>
          <label>Description</label>
          <input type="text" value={descriptionState} onChange={changeDescriptionHandler}></input>
          <label>Price $</label>
          <input type="text" value={priceState} onChange={changePriceHandler}></input>
          <label>Image</label>
          <Image url={props.image_url} onDelete={deleteImageHandler} onChange={changeImageHandler}/>
      </Modal.body>
    </div>
  );
}

Modifier.protoType = {
//   id: PropTypes.number.isRquired,
//   active: PropTypes.number.isRequired,
//   name: PropTypes.string.isRequired,
//   description: PropTypes.string,
//   price: PropTypes.number.isRequired,
//   showPrice: PropTypes.number.isRequired
};

export default Modifier;