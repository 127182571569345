import * as actionTypes from '../actionTypes';
import axios from '../../../axios';
import { getModifierGroups } from './modifierGroups';
import { sort } from './_utils';

const setSortModifiers = (modifierGroupId, oldIndex, newIndex) => {
  return {
    type: actionTypes.MENU_BUILDER_SORT_MODIFIERS,
    modifierGroupId,
    oldIndex,
    newIndex
  }
}

// PUBLIC CLASSES

export const sortModifiers = ({ modifierGroupId, oldIndex, newIndex, selectedItemId, replacedItemId, successCallback }) => {
  return dispatch => {
    sort('modifiers', oldIndex, newIndex, selectedItemId, replacedItemId, successCallback)
    dispatch(setSortModifiers(modifierGroupId, oldIndex, newIndex));
  }
}

export const addModifier = ({ data, successCallback }) => {
  return dispatch => {
    axios.post(`/menu_builder/modifiers`, data)
      .then(res => {
        successCallback();
        dispatch(getModifierGroups());
      });
  }
}

export const updateModifier = ({ id, data, headers = null, successCallback }) => {
  return dispatch => {
    if (data instanceof FormData) {
      data.append('_method', 'PATCH');
      axios.post(`/menu_builder/modifiers/${id}`, data, { headers })
        .then(res => {
          successCallback();
          dispatch(getModifierGroups());
        });
    } else {
      axios.patch(`/menu_builder/modifiers/${id}`, data)
        .then(res => {
          successCallback();
          dispatch(getModifierGroups());
        });
    }
  }
}

export const deleteModifier = ({ id, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/modifiers/${id}`)
      .then(res => {
        successCallback();
        dispatch(getModifierGroups());
      });
  }
}

export const deleteModifierImage = ({ id, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/modifiers/${id}/image`)
      .then(res => {
        successCallback();
        dispatch(getModifierGroups());
      });
  }
}