import React, { useContext, useState } from 'react';
import Modal from '../../../UI/Modal/Modal';
import ItemToolbar from '../../Helpers/ItemToolbar/ItemToolbar';
import SortableList from '../../../../hoc/SortableList/SortableList';
import './Category.css';
import Subcategory from '../Subcategory/Subcategory';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { addSubcategory, updateCategory, deleteCategory, sortSubcategories } from '../../../../store/actions/menuBuilder/index';
import ShowInTimeRangeRedactor from '../../Redactors/ShowInTimeRangeRedactor/ShowInTimeRangeRedactor';
import Redactors from '../../Redactors/Redactors';
import RedactorsLegend from '../../Redactors/RedactorsLegend/RedactorsLegend';

function Category(props) {
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [detailsState, setDetailsState] = useState(0);
  const [nameState, setNameState] = useState(props.name);
  const [descriptionState, setDescriptionState] = useState(props.description || '');
  const [isBeverage, setIsBeverage] = useState(props.is_beverage || 0);

  const setDetailsHandler = () => setDetailsState(!detailsState)
  const changeNameHandler = event => setNameState(event.target.value)
  const changeDescriptionHandler = event => setDescriptionState(event.target.value)
  const changeBeverageHandler = event => setIsBeverage(event.target.checked)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(sortSubcategories({
      categoryId: props.id,
      oldIndex,
      newIndex,
      selectedItemId: props.subcategories[oldIndex].id,
      replacedItemId: props.subcategories[newIndex].id,
      successCallback: () => NotificationManager.success('Saved')
    }));
  }
  const updateCategoryHandler = () => {
    setSaving(true);
    dispatch(updateCategory({
      id: props.id,
      data: {
        name: nameState,
        description: descriptionState,
        is_beverage: isBeverage
      },
      successCallback: () => {
        NotificationManager.success('Saved');
        setSaving(false);
      }
    }));
  }
  const newSubcategoryHandler = () => {
    dispatch(addSubcategory({
      data: {
        name: 'New subcategory',
        category_id: props.id,
        is_beverage: isBeverage
      },
      successCallback: () => NotificationManager.success('Subcategory has been added')
    }));
    setDetailsState(1);
  }
  const toggleActiveHandler = () => {
    dispatch(updateCategory({
      id: props.id,
      data: { active: !props.active },
      successCallback: () => NotificationManager.success('Saved')
    }));
  };
  const deleteCategoryHandler = () => {
    if (!window.confirm('Do you want to remove the category with all subcategories and items?')) return;

    dispatch(deleteCategory({
      id: props.id,
      successCallback: () => NotificationManager.success('Category has been deleted')
    }))
  }


  const subcategories = props.subcategories.map(subcategory => <Subcategory key={subcategory.id} {...subcategory} />);
  const sortableSubcategories = <SortableList items={subcategories} onSortEnd={onSortEnd} lockAxis="y" useDragHandle />;

  return (
    <div>
      <div className="mb_category">
        <div className="mb_category__details">
          <p className="mb_category__name">{props.name}</p>
          <div className="d-flex align-items-center">
            <RedactorsLegend redactors={props.redactors} />
            {props.is_beverage ? <i className="mbi ion-coffee text-secondary ml-2" title="Is beverage category" /> : null}
          </div>
          <p className="mb_category__description">{props.description}</p>
        </div>

        <ItemToolbar
          editModalId={`modal-category-${props.id}`}  
          deleteHandler={deleteCategoryHandler}
          newHandler={newSubcategoryHandler}
          toggleDetailsHandler={setDetailsHandler}
          activeHandler={toggleActiveHandler}
          detailsVisibility={detailsState}
          active={props.active}
        />
      </div>

      {detailsState ? sortableSubcategories : null}

      <Modal.body id={`modal-category-${props.id}`} title="Edit category">
        <label>Category name</label>
        <input type="text" defaultValue={nameState} onChange={changeNameHandler}/>
        <label>
          <input type="checkbox" defaultChecked={isBeverage} onChange={changeBeverageHandler} style={{width: 'auto', marginRight: '10px'}}/>
          Is beverage category
        </label>
        <label>Category description</label>
        <input type="text" defaultValue={descriptionState} onChange={changeDescriptionHandler}/>

        <div className="text-right mt-2">
        {saving 
            ? <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...</button>
            : <button className="btn btn-success" onClick={updateCategoryHandler}>Save</button>}
        </div>

        <Redactors 
          objectId={props.id} 
          objectType={'category'}
          afterUpdate={updateCategoryHandler}
          redactors={[
            { title: 'Show only in a range of time', component: ShowInTimeRangeRedactor }
          ]} />
      </Modal.body>
    </div>
  );

}

export default Category;