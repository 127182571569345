import * as actionTypes from '../actionTypes';
import axios from '../../../axios';
import { getMenus } from './menus';
import { sort } from './_utils';

const setSortSubategories = (categoryId, oldIndex, newIndex) => {
  return {
    type: actionTypes.MENU_BUILDER_SORT_SUBCATEGORIES,
    categoryId,
    oldIndex,
    newIndex
  }
}


// PUBLIC CLASSES 

export const sortSubcategories = ({ categoryId, oldIndex, newIndex, selectedItemId, replacedItemId, successCallback }) => {
  return dispatch => {
    sort('subcategories', oldIndex, newIndex, selectedItemId, replacedItemId, successCallback)
    dispatch(setSortSubategories(categoryId, oldIndex, newIndex));
  }
}

export const addSubcategory = ({ data, successCallback }) => {
  return dispatch => {
    axios.post(`/menu_builder/subcategories`, data)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      })
  }
}

export const deleteSubcategory = ({ id, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/subcategories/${id}`)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}

export const updateSubcategory = ({ id, data, headers = null, successCallback }) => {
  return dispatch => {
    if (data instanceof FormData) {
      data.append('_method', 'PATCH');
      axios.post(`/menu_builder/subcategories/${id}`, data, { headers })
        .then(res => {
          successCallback();
          dispatch(getMenus());
        });
    } else {
      axios.patch(`/menu_builder/subcategories/${id}`, data)
        .then(res => {
          successCallback();
          dispatch(getMenus());
        });
    }
  }
}

export const deleteSubcategoryImage = ({ id, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/subcategories/${id}/image`)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}
