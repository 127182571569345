import * as actionTypes from '../actionTypes';
import axios from '../../../axios';


const setModifierGroups = modifiersGroups => {
  return {
    type: actionTypes.MENU_BUILDER_SET_MODIFIER_GROUPS,
    modifiersGroups
  };
}

// PUBLIC CLASSES

export const getModifierGroups = () => {
  return (dispatch, getState) => {
    const locationId = getState().menuBuilder.locationId

    axios.get(`/locations/${locationId}/modifiers`)
      .then(res => dispatch(setModifierGroups(res.data)));
  }
}

export const addModifierGroup = ({ name, type, successCallback }) => {
  return (dispatch, getState) => {
    const locationId = getState().menuBuilder.locationId;

    axios.post(`/menu_builder/modifier_groups`, {
      name,
      type,
      location_id: locationId
    }).then(res => {
        successCallback();
        dispatch(getModifierGroups());
      });
  }
}

export const updateModifierGroup = ({ groupId, data, successCallback }) => {
  return dispatch => {
    axios.patch(`/menu_builder/modifier_groups/${groupId}`, data)
      .then(res => {
        successCallback();
        dispatch(getModifierGroups());
      }); 
  }
}

export const deleteModifierGroup = ({ groupId, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/modifier_groups/${groupId}`)
      .then(res => {
        successCallback();
        dispatch(getModifierGroups());
      });
  }
}