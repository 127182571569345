import React, { Component } from 'react';
import axios from '../../../axios';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions/actionTypes';
import { NotificationManager } from 'react-notifications';
import Hours from '../../../components/Restaurants/Hours/Hours';

class RestaurantHours extends Component {
  state = {
    loading: true,
    hours: []
  }
  locationId = this.props.match.params.id;

  componentDidMount() {
    this.fetchHours();
    this.props.changePageTitle('Restaurant hours');
  }

  fetchHours() {
    axios.get(`/locations/${this.locationId}/hours`)
      .then(res => {
        const hoursWithId = res.data.map(x => ({...x, uId: Math.random()}))
        this.setState({ hours: hoursWithId });
        this.setState({ loading: false });
      });
  }

  onDelete(uId) {
    this.setState({ hours: this.state.hours.filter(x => x.uId !== uId) });
  }

  onUpdate(uId, value) {
    const newHours = [...this.state.hours]
    const index = newHours.findIndex(x => x.uId === uId);
    newHours[index] = {...newHours[index], ...value};

    this.setState({ hours: newHours });
  }

  addHour(type) {
    const newHours = [...this.state.hours]
    newHours.push({
      type: type,
      day_a: 'Mo',
      day_b: 'Mo',
      time_a: '09:00:00',
      time_b: '17:00:00',
      uId: Math.random()
    })
    this.setState({ hours: newHours })
  }

  onSubmit() {
    axios.patch(`/locations/${this.locationId}/hours`, this.state.hours)
      .then(() => {
        NotificationManager.success('Saved');
      })
  }

  render() {
    return <Hours 
      loading={this.state.loading} 
      hours={this.state.hours}
      addHour={this.addHour.bind(this)}
      onSubmit={this.onSubmit.bind(this)}
      onUpdate={this.onUpdate.bind(this)}
      onDelete={this.onDelete.bind(this)} />
  }
}

const mapDispatchToProps = dispatch => ({
  changePageTitle: (title) => dispatch({ type: actionTypes.UI_CHANGE_PAGE_TITLE, title })
});

export default connect(null, mapDispatchToProps)(RestaurantHours);