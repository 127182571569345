import * as actionTypes from '../actions/actionTypes';

const initialState = {
  currentPageTitle: 'Dashboard',
  preloader: true
};


const hidePreloader = state => {
  return {
    ...state,
    preloader: false
  }
}

const changePageTitle = (state, title) => {
  return {
    ...state,
    currentPageTitle: title
  }
}


const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UI_PRELOADER_HIDE: return hidePreloader(state);
    case actionTypes.UI_CHANGE_PAGE_TITLE: return changePageTitle(state, action.title);
    default: return state;
  }
}

export default uiReducer;