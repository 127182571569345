import React from 'react'
import PropTypes from 'prop-types'

import './ModifierGroup.css'

const ModifierGroup = props => <div className="mb_modifiers_group__item">{props.name}</div>


ModifierGroup.propTypes = {
  active: PropTypes.number.isRequired, 
  free_modifiers: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  modifiers: PropTypes.array
}

export default ModifierGroup