import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout/Layout';
import ContentBuilder from './ContentBuilder/ContentBuilder'
import Preloader from '../components/Layout/Preloader';
import './App.css';
import { logout, authenticate } from '../store/actions/index';

function App(props) {
  // authenticate when page reload
  useEffect(() => {
    props.authenticate();
  }, []);

  return (
    <React.Fragment>
      <Preloader show={props.preloader} />
      
      <Layout pageTitle={props.pageTitle} authenticated={props.authenticated} onLogout={props.logout}>
        <ContentBuilder />
      </Layout>
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  pageTitle: state.ui.currentPageTitle,
  preloader: state.ui.preloader,
  authenticated: state.auth.authenticated,
  user: state.auth.user
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  authenticate: () => dispatch(authenticate())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
