import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Group.css'
import Modal from '../../../UI/Modal/Modal';
import Modifier from '../Modifier/Modifier';
import ItemToolbar from '../../../MenuBuilder/Helpers/ItemToolbar/ItemToolbar';
import SortableList from '../../../../hoc/SortableList/SortableList';
import { useDispatch } from 'react-redux';
import { updateModifierGroup, deleteModifierGroup, sortModifiers, addModifier } from '../../../../store/actions/menuBuilder/index';
import { NotificationManager } from 'react-notifications';

function Group(props) {
  const dispatch = useDispatch();

  const [detailsState, setDetailsState] = useState(1);
  const [nameState, setNameState] = useState(props.name);
  const [minState, setMinState] = useState(props.min);
  const [maxState, setMaxState] = useState(props.max);
  const [typeState, setTypeState] = useState(props.type);
  const [freeModifiersState, setFreeModifiersState] = useState(props.free_modifiers);

  const changeNameHandler = event => setNameState(event.target.value);
  const changeMinHandler = event => setMinState(event.target.value);
  const changeMaxHandler = event => setMaxState(event.target.value);
  const changeTypeHandler = event => setTypeState(event.target.value);
  const changeFreeModifiersHandler = event => setFreeModifiersState(event.target.value);

  const deleteGroupHandler = () => {
    if (!window.confirm('Do you want to remove the group with modifiers?')) return;

    dispatch(deleteModifierGroup({
      groupId: props.id,
      successCallback: () => NotificationManager.success('Group has been deleted') 
    }));
  }
  const newModifierHandler = () => {
    dispatch(addModifier({
      data: {
        name: 'New modifier',
        modifier_group_id: props.id
      },
      successCallback: () => NotificationManager.success('Modifier has been added')
    }));
  };
  const toggleActiveHandler = () => {
    dispatch(updateModifierGroup({
      groupId: props.id,
      data: { active: !props.active },
      successCallback: () => NotificationManager.success('Saved')
    }));
  }
  const updateGroupHandler = () => {
    dispatch(updateModifierGroup({
      groupId: props.id,
      data: {
        name: nameState,
        min: minState || 0,
        max: maxState || 0,
        type: typeState,
        free_modifiers: freeModifiersState || 0
      },
      successCallback: () => NotificationManager.success('Saved')
    }));
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    dispatch(sortModifiers({
      modifierGroupId: props.id,
      oldIndex,
      newIndex,
      selectedItemId: props.modifiers[oldIndex].id,
      replacedItemId: props.modifiers[newIndex].id,
      successCallback: () => NotificationManager.success('Saved')
    }));
  }
  

  const modifiers = props.modifiers.map(modifier => <Modifier key={modifier.id} {...modifier} />);
  const sortableModifiers = <SortableList items={modifiers} onSortEnd={onSortEnd} lockAxis="y" useDragHandle helperClass='sortableHelper' />;

  return (
    <section key={props.id}>
      <div className="mb_modifiers_groups_details">
        <div className="mb_modifiers_groups_details__info">
          <p className="mb_modifiers_groups_details__name">{props.name}</p>
          <p className="mb_modifiers_groups_details__settings">Min: {props.min}, Max: {props.max}, Free: {props.free_modifiers}, Type: {props.type}, Modifiers: {props.modifiers.length}</p>
        </div>

        <ItemToolbar
          editModalId={`modal-group-${props.id}`} 
          deleteHandler={deleteGroupHandler}
          newHandler={newModifierHandler}
          toggleDetailsHandler={() => setDetailsState(!detailsState)}
          activeHandler={toggleActiveHandler}
          detailsVisibility={detailsState}
          active={props.active}
        />
      </div>
      {detailsState ? sortableModifiers : null}

      <Modal.body id={`modal-group-${props.id}`} title="Edit Group" save={updateGroupHandler}>
        <label>Name</label>
        <input type="text" value={nameState} onChange={changeNameHandler}></input>
        <label>Min</label>
        <input type="number" value={minState} onChange={changeMinHandler}></input>
        <label>Max</label>
        <input type="number" value={maxState} onChange={changeMaxHandler}></input>
        <label>Type</label>
        <select value={typeState} onChange={changeTypeHandler}>
          <option value="checkbox">Checkbox</option>
          <option value="radio">Radio</option>
          <option value="select">Dropdown list</option>
        </select>
        <label>Free modifiers</label>
        <input type="number" value={freeModifiersState} onChange={changeFreeModifiersHandler}></input>
      </Modal.body>
    </section>
  );
}

Group.propTypes = {
  // modifiersGroups: PropTypes.array,
  // addNewModifierGroup: PropTypes.func,
  // onDeleteModifierGroup: PropTypes.func,
  // addNewModifier: PropTypes.func,
};

export default Group;