import * as actionTypes from '../actionTypes';
import axios from '../../../axios';
import { getMenus } from './menus';
import { sort } from './_utils';

const setSortVariationModifiers = (variationId, oldIndex, newIndex) => {
  return {
    type: actionTypes.MENU_BUILDER_SORT_VARIATION_MODIFIERS,
    variationId,
    oldIndex,
    newIndex
  }
}

// PUBLIC CLASSES 

export const sortVariationModifiers = ({ variationId, oldIndex, newIndex, selectedItemId, replacedItemId, successCallback }) => {
  return dispatch => {
    sort('variation_modifiers', oldIndex, newIndex, selectedItemId, replacedItemId, successCallback, variationId)
    dispatch(setSortVariationModifiers(variationId, oldIndex, newIndex));
  }
}

export const addVariation = ({ data, successCallback }) => {
  return dispatch => {
    axios.post(`/menu_builder/variations`, data)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}

export const deleteVariation = ({ id, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/variations/${id}`)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}

export const updateVariation = ({ id, data, headers = null, successCallback }) => {
  return dispatch => {
    if (data instanceof FormData) {
      data.append('_method', 'PATCH');
      axios.post(`/menu_builder/variations/${id}`, data, { headers })
        .then(res => {
          successCallback();
          dispatch(getMenus());
        });
    } else {
      axios.patch(`/menu_builder/variations/${id}`, data)
      .then(res => {
          successCallback();
          dispatch(getMenus());
        });
    }
  }
}

export const deleteVariationImage = ({ id, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/variations/${id}/image`)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}