import React from 'react';
import { MDBBtn } from 'mdbreact';

function Hour(props) {
  const deleteHour = () => props.onDelete(props.uId);
  const updateDayA = e => props.onUpdate(props.uId, { day_a: e.target.value});
  const updateDayB = e => props.onUpdate(props.uId, { day_b: e.target.value});
  const updateTimeA = e => props.onUpdate(props.uId, { time_a: e.target.value});
  const updateTimeB = e => props.onUpdate(props.uId, { time_b: e.target.value});

  return (
    <tr>

      <td className="pt-2 pb-0">
        <div className="row">
          <div className="col-md-6">
            <select className="form-control" value={props.day_a} onChange={updateDayA}>
                <option value="Mo">Monday</option>
                <option value="Tu">Tuesday</option>
                <option value="We">Wednesday</option>
                <option value="Th">Thursday</option>
                <option value="Fr">Friday</option>
                <option value="Sa">Saturday</option>
                <option value="Su">Sunday</option>
            </select>
          </div>
          <div className="col-md-6">
            <input className="form-control" type="time" value={props.time_a}  onChange={updateTimeA}/>
          </div>
        </div>
      </td>

      <td>:</td>

      <td className="pt-2 pb-0">
        <div className="row">
          <div className="col-md-6">
            <select className="form-control" value={props.day_b} onChange={updateDayB}>
              <option value="Mo">Monday</option>
              <option value="Tu">Tuesday</option>
              <option value="We">Wednesday</option>
              <option value="Th">Thursday</option>
              <option value="Fr">Friday</option>
              <option value="Sa">Saturday</option>
              <option value="Su">Sunday</option>
            </select>
          </div>
          <div className="col-md-6">
            <input className="form-control" type="time" value={props.time_b}  onChange={updateTimeB}/>
          </div>
        </div>
      </td>

      <td className="pt-1 pb-0">
        <MDBBtn size="lg" onClick={deleteHour}><i className="fa fa-trash"></i></MDBBtn>
      </td>

    </tr>
  );
}

export default Hour;