import React, { Component } from 'react';
import { MDBBtn } from 'mdbreact';
import axios from '../../../axios';
import { withRouter } from 'react-router-dom';
import Loading from '../../../components/UI/Loading/Loading';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions/actionTypes';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import { NotificationManager } from 'react-notifications';
import TimezoneInput from '../../../components/Forms/TimezoneInput/TimezoneInput';
import CountryInput from '../../../components/Forms/CountryInput/CountryInput';
import StateInput from '../../../components/Forms/StateInput/StateInput';

class Restaurants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      phone: '',
      country: 'United States',
      street: '',
      city: '',
      state: 'US',
      zip: '',
      pickup: 0,
      delivery: 0,
      curbside: 0,
      dine_in: 0,
      all: 0,
      timezone: '',
      stite_url: '',
      logo_url: '',
      tax_rate: 0,
      tip_on: 0,
      locked: 0,
      raporting_enabled: 0,
      subtotal_fee: 7,
      total_fee: 3,
      is_active: 0,
      high_end_ui: 0,

      loading: false
    };
    this.editMode = false;

    if (props.editMode) {
      this.editMode = true;
      this.state.loading = true;
    }
  }

  componentDidMount() {
      this.props.changePageTitle(`Restaurant details`);

      if (this.editMode) {
        this.fetchRestaurant();
      }
  }

  fetchRestaurant() {
    axios.get(`/locations/${this.props.match.params.id}`)
      .then(res => {
        const restaurant = res.data;
        this.setState({ ...restaurant })
        this.setState({ loading: false })
      });
  }

  async storeRestaurant() {
    return await axios.post(`/locations`, this.state)
      .then(res => {
        NotificationManager.success('New restaurant has been created');
        return res.data;
      }).catch(e => {
        console.log(e);
        NotificationManager.error('Something went wrong');
      })
  }

  async updateRestaurant() {
    await axios.patch(`/locations/${this.state.id}`, this.state)
      .then(() => {
        NotificationManager.success('Saved');
      }).catch(e => {
        console.log(e);
        NotificationManager.error('Something went wrong')
      })
  }

  async onSubmit() {
    if (this.editMode) {
      await this.updateRestaurant();
      this.props.history.push(`/restaurants`);
    } else {
      const restaurant = await this.storeRestaurant();
      this.props.history.push(`/restaurants/${restaurant.id}/menu_builder`);
    }
  }

  async onDelete() {
    if (window.confirm('Are you sure you want to delete restaurant and its menus?')) {
      await axios.delete(`/locations/${this.state.id}`, this.state)
        .then(() => {
          NotificationManager.success('Restaurant has been deleted')
        });
      this.props.history.push(`/restaurants`);
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="card m-b-20">
            <div className="card-body">

              {this.editMode ? (
                <div className="text-right">
                  <MDBBtn color="danger" size="sm" onClick={this.onDelete.bind(this)}>Delete restaurant</MDBBtn>
                </div>
              ) : null}

              <h4 className="mt-0 header-title mb-5">{this.editMode ? 'Edit': 'New'} Restaurant</h4>

              {this.state.loading ? <Loading /> : (
                <div>
                  <div className="form-group row">
                      <label htmlFor="input-name" className="col-sm-2 col-form-label">Name *</label>
                      <div className="col-sm-10">
                          <input className="form-control" type="text" placeholder="" id="input-name" value={this.state.name} onChange={e => this.setState({ name: e.target.value })} />
                      </div>
                  </div> 

                  {/* <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Active</label>
                      <div className="col-sm-10">
                          <select className="form-control" value={this.state.is_active} onChange={e => this.setState({ is_active: e.target.value })}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                      </div>
                  </div>  */}

                  <div className="form-group row">
                      <label htmlFor="input-phone" className="col-sm-2 col-form-label">Phone</label>
                      <div className="col-sm-10">
                          <PhoneInput defaultCountry="US" placeholder="Enter phone number" value={this.state.phone} onChange={value => this.setState({ phone: value })}  />
                      </div>
                  </div>

                  <div className="form-group row">
                      <label htmlFor="input-country" className="col-sm-2 col-form-label">Country</label>
                      <div className="col-sm-10">
                          <CountryInput value={this.state.country}  onChange={value => this.setState({ country: value })} />
                      </div>
                  </div>

                  <div className="form-group row">
                      <label htmlFor="input-street" className="col-sm-2 col-form-label">Street</label>
                      <div className="col-sm-10">
                          <input className="form-control" type="text" placeholder="" id="input-street" value={this.state.street} onChange={e => this.setState({ street: e.target.value })} />
                      </div>
                  </div>

                  <div className="form-group row">
                      <label htmlFor="input-city" className="col-sm-2 col-form-label">City</label>
                      <div className="col-sm-10">
                          <input className="form-control" type="text" placeholder="" id="input-city" value={this.state.city} onChange={e => this.setState({ city: e.target.value })} />
                      </div>
                  </div>

                  <div className="form-group row">
                      <label htmlFor="input-state" className="col-sm-2 col-form-label">State</label>
                      <div className="col-sm-10">
                          {this.state.country === 'US' ? (
                            <StateInput value={this.state.state} onChange={value => this.setState({ state: value })}/>
                          ) : (
                            <input className="form-control" type="text" placeholder="" id="input-state" value={this.state.state} onChange={e => this.setState({ state: e.target.value })} />
                          )}
                      </div>
                  </div>

                  <div className="form-group row">
                      <label htmlFor="input-zip" className="col-sm-2 col-form-label">Zip</label>
                      <div className="col-sm-10">
                          <input className="form-control" type="text" placeholder="" id="input-zip" value={this.state.zip} onChange={e => this.setState({ zip: e.target.value })} />
                      </div>
                  </div>

                  <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Pickup</label>
                      <div className="col-sm-10">
                          <select className="form-control" value={this.state.pickup} onChange={e => this.setState({ pickup: e.target.value })}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                      </div>
                  </div>

                  <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Delivery</label>
                      <div className="col-sm-10">
                          <select className="form-control" value={this.state.delivery} onChange={e => this.setState({ delivery: e.target.value })}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                      </div>
                  </div>

                  <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Curbside</label>
                      <div className="col-sm-10">
                          <select className="form-control" value={this.state.curbside} onChange={e => this.setState({ curbside: e.target.value })}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                      </div>
                  </div>

                  <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Dine in</label>
                      <div className="col-sm-10">
                          <select className="form-control" value={this.state.dine_in} onChange={e => this.setState({ dine_in: e.target.value })}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                      </div>
                  </div>

                  <div className="form-group row">
                      <label className="col-sm-2 col-form-label">All</label>
                      <div className="col-sm-10">
                          <select className="form-control" value={this.state.all} onChange={e => this.setState({ all: e.target.value })}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                      </div>
                  </div>

                  <div className="form-group row">
                      <label htmlFor="input-timezone" className="col-sm-2 col-form-label">Timezone</label>
                      <div className="col-sm-10">
                        <TimezoneInput onChange={value => this.setState({ timezone: value })} value={this.state.timezone}/>
                    </div>
                  </div>  

                  <div className="form-group row">
                      <label htmlFor="input-logo-url" className="col-sm-2 col-form-label">Logo URL</label>
                      <div className="col-sm-10">
                          <input className="form-control" type="text" placeholder="" id="input-logo-url" placeholder="https://..." value={this.state.logo_url} onChange={e => this.setState({ logo_url: e.target.value })} />
                      </div>
                  </div>

                  <div className="form-group row">
                      <label htmlFor="input-taxt-rate" className="col-sm-2 col-form-label">Tax rate</label>
                      <div className="col-sm-10">
                          <input className="form-control" type="text" placeholder="" id="input-taxt-rate" value={this.state.tax_rate} onChange={e => this.setState({ tax_rate: e.target.value })} />
                      </div>
                  </div>

                  <div className="form-group row">
                      <label htmlFor="input-subtotal-fee" className="col-sm-2 col-form-label">Subtotal Fee</label>
                      <div className="col-sm-10">
                          <input className="form-control" type="text" placeholder="" id="input-subtotal-fee" value={this.state.subtotal_fee} onChange={e => this.setState({ subtotal_fee: e.target.value })} />
                      </div>
                  </div>

                  <div className="form-group row">
                      <label htmlFor="input-total-fee" className="col-sm-2 col-form-label">Total Fee</label>
                      <div className="col-sm-10">
                          <input className="form-control" type="text" placeholder="" id="input-total-fee" value={this.state.total_fee} onChange={e => this.setState({ total_fee: e.target.value })} />
                      </div>
                  </div>

                  <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Tip on</label>
                      <div className="col-sm-10">
                          <select className="form-control" value={this.state.tip_on} onChange={e => this.setState({ tip_on: e.target.value })}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                      </div>
                  </div>

                  <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Locked</label>
                      <div className="col-sm-10">
                          <select className="form-control" value={this.state.locked} onChange={e => this.setState({ locked: e.target.value })}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                      </div>
                  </div>

                  <div className="form-group row">
                      <label className="col-sm-2 col-form-label">Reporting enabled</label>
                      <div className="col-sm-10">
                          <select className="form-control" value={this.state.reporting_enabled} onChange={e => this.setState({ reporting_enabled: e.target.value })}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                      </div>
                  </div>

                  <div className="form-group row">
                      <label className="col-sm-2 col-form-label">High End UI</label>
                      <div className="col-sm-10">
                          <select className="form-control" value={this.state.high_end_ui} onChange={e => this.setState({ high_end_ui: +e.target.value })}>
                              <option value="0">No</option>
                              <option value="1">Yes</option>
                          </select>
                      </div>
                  </div>


                  <div className="text-right">
                    <MDBBtn color="success" onClick={this.onSubmit.bind(this)}>{this.editMode ? 'Update': 'Add'} restaurant</MDBBtn>
                  </div>

                </div>
              )}
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
    changePageTitle: (title) => dispatch({ type: actionTypes.UI_CHANGE_PAGE_TITLE, title })
});

export default connect(null, mapDispatchToProps)(withRouter(Restaurants));
