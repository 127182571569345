import React, { useState }  from 'react';
import PropTypes from 'prop-types';
import './Subcategory.css';
import Item from '../Item/Item';
import ItemToolbar from '../../Helpers/ItemToolbar/ItemToolbar';
import Modal from '../../../UI/Modal/Modal';
import Image from '../../Helpers/Image/Image';
import SortableList from '../../../../hoc/SortableList/SortableList';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { sortItems, updateSubcategory, deleteSubcategoryImage, deleteSubcategory, addItem } from '../../../../store/actions/menuBuilder/index';
import ShowInTimeRangeRedactor from '../../Redactors/ShowInTimeRangeRedactor/ShowInTimeRangeRedactor';
import Redactors from '../../Redactors/Redactors';
import RedactorsLegend from '../../Redactors/RedactorsLegend/RedactorsLegend';

function Subcategory(props) {
  const dispatch = useDispatch();

  const [saving, setSaving] = useState(false);
  const [detailsState, setDetailsState] = useState(1);
  const [nameState, setNameState] = useState(props.name);
  const [hideSubcategorySate, setHideSubcategorySate] = useState(props.name === 'no_subcategory');
  const [imageState, setImageState] = useState(null);
  const visibilityChangeHandler = event => {
    const hide = event.target.checked;
    setHideSubcategorySate(hide);
    setNameState('no_subcategory');
  }

  const setDetailsHandler = () => setDetailsState(!detailsState);
  const changeNameHandler = event => setNameState(event.target.value);
  const changeImageHandler = event => setImageState(event.target.files[0]);

  const updateSubcategoryHandler = () => {
    setSaving(true);
    const data = new FormData();
    data.append('name', nameState);
    if (imageState) data.append('image', imageState, imageState.name);

    dispatch(updateSubcategory({
      id: props.id,
      data: data,
      headers: { 'content-type': 'multipart/form-data' },
      successCallback: () => {
        NotificationManager.success('Saved');
        setSaving(false);
      }
    }));
  }
  const newItemHandler = () => {
    dispatch(addItem({
      data: {
        name: 'New item',
        subcategory_id: props.id
      },
      successCallback: () => NotificationManager.success('Item has been added')
    }));
    setDetailsState(1);
  }
  const toggleActiveHandler = () => {
    dispatch(updateSubcategory({
      id: props.id,
      data: { active: !props.active },
      successCallback: () => NotificationManager.success('Saved')
    }));
  }
  const deleteSubcategoryHandler = () => {
    if (!window.confirm('Do you want to remove the subcategory with all items?')) return
    
    dispatch(deleteSubcategory({
      id: props.id,
      successCallback: () => NotificationManager.success('Subcategory has been deleted')
    }));
  };
  const deleteImageHandler = () => {
    dispatch(deleteSubcategoryImage({
      id: props.id,
      successCallback: () => NotificationManager.success('Image has been deleted')
    }));
    setImageState(null);
  }
  const onSortEndHandler = ({ oldIndex, newIndex }) => {
    dispatch(sortItems({
      subcategoryId: props.id,
      oldIndex,
      newIndex,
      selectedItemId: props.items[oldIndex].id,
      replacedItemId: props.items[newIndex].id,
      successCallback: () => NotificationManager.success('Saved')
    }));
  }


  const items = props.items.map(item => (
    <Item 
      key={item.id} 
      {...item} 

      onAddItem={props.onAddItem}
      onDeleteItem={props.onDeleteItem}
      onUpdateItem={props.onUpdateItem}
      onDeleteItemImage={props.onDeleteItemImage}
      onSortItems={props.onSortItems}

      onAddVariation={props.onAddVariation}
      onDeleteVariation={props.onDeleteVariation}
      onUpdateVariation={props.onUpdateVariation}
      onDeleteVariationImage={props.onDeleteVariationImage}
    />
  ));
  const sortableItems = <SortableList items={items} onSortEnd={onSortEndHandler} lockAxis="y" useDragHandle />

  return (
    <div>
      <div className="mb_subcategory">
      <p className={`mb_subcategory__name ${hideSubcategorySate ? 'hidden' : ''}`}>{props.name}</p>
      <RedactorsLegend redactors={props.redactors} />
      {props.image_url ? <img alt="" src={props.image_url} className="mb_subcategory_image" style={{ height: '30px', marginLeft: '20px', borderRadius: '5px' }} /> : null}

      <ItemToolbar
          editModalId={`modal-subcategory-${props.id}`} 
          deleteHandler={deleteSubcategoryHandler}
          newHandler={newItemHandler}
          toggleDetailsHandler={setDetailsHandler}
          activeHandler={toggleActiveHandler}
          detailsVisibility={detailsState}
          active={props.active}
        />
      </div>

      {detailsState ? sortableItems : null}

      <Modal.body id={`modal-subcategory-${props.id}`} title="Edit subcategory" >
        {!hideSubcategorySate ? (
          <div>
            <label>Name</label>
            <input type="text" defaultValue={nameState} onChange={changeNameHandler}/>
            <label>Image</label>
            <Image url={props.image_url} onDelete={deleteImageHandler} onChange={changeImageHandler}/>
          </div>
         ) : null}
         <label>
           <input type="checkbox" defaultChecked={hideSubcategorySate} onChange={visibilityChangeHandler} style={{width: 'auto', marginRight: '10px'}}/>
           Do not show the subcategory name
        </label>

        <div className="text-right mt-2">
          {saving 
            ? <button className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Saving...</button>
            : <button className="btn btn-success" onClick={updateSubcategoryHandler}>Save</button>}
          
        </div>

        <Redactors 
          // menuId={props.}
          objectId={props.id} 
          objectType={'subcategory'}
          afterUpdate={updateSubcategoryHandler}
          redactors={[
            { title: 'Show only in a range of time', component: ShowInTimeRangeRedactor }
          ]} />
      </Modal.body>
    </div>
  );
}

export default Subcategory;