import clonedeep from 'lodash.clonedeep';
import arrayMove from 'array-move';
import * as actions from '../actions/actionTypes';

const initialState = {
  menus: [],
  modifiersGroups: [],
  locationId: null,
};

const setLocationId = (state, id) => {
  return { ...state, locationId: id };
}

const setMenu = (state, menus) => {
  return { ...state, menus };
}

const setModifierGroups = (state, modifiersGroups) => {
  return { ...state, modifiersGroups };
}

const sortCategories = (state, action) => {
  const menus = clonedeep(state.menus);
  const menuId = action.menuId;
  const oldIndex = action.oldIndex;
  const newIndex = action.newIndex;

  const menuIndex = menus.findIndex(x => x.id === menuId);
  menus[menuIndex].categories = arrayMove(menus[menuIndex].categories, oldIndex, newIndex);

  return { ...state, menus };
}

const sortSubategories = (state, action) => {
  const menus = clonedeep(state.menus);
  const categoryId = action.categoryId;
  const oldIndex = action.oldIndex;
  const newIndex = action.newIndex;

  let menuIndex;
  let categoryIndex;

  menus.forEach((menu, mIndex) => {
    menu.categories.forEach((category, cIndex) => {
      if (category.id === categoryId) {
        menuIndex = mIndex;
        categoryIndex = cIndex;
      }
    })
  });
  
  menus[menuIndex]
    .categories[categoryIndex]
    .subcategories = arrayMove(menus[menuIndex]
                                .categories[categoryIndex]
                                .subcategories,
                              oldIndex, newIndex);

  return { ...state, menus };
}

const sortItems = (state, action) => {
  const menus = clonedeep(state.menus);
  const subcategoryId = action.subcategoryId;
  const oldIndex = action.oldIndex;
  const newIndex = action.newIndex;

  let menuIndex;
  let categoryIndex;
  let subcategoryIndex;

  menus.forEach((menu, mIndex) => {
    menu.categories.forEach((category, cIndex) => {
      category.subcategories.forEach((subcategory, sIndex) => {
        if (subcategory.id === subcategoryId) {
          menuIndex = mIndex;
          categoryIndex = cIndex;
          subcategoryIndex = sIndex;
        }
      })
    })
  });
  
  menus[menuIndex]
    .categories[categoryIndex]
    .subcategories[subcategoryIndex]
    .items = arrayMove(menus[menuIndex]
                        .categories[categoryIndex]
                        .subcategories[subcategoryIndex]
                        .items,
                      oldIndex, newIndex);

  return { ...state, menus };
}

const sortModifiers = (state, action) => {
  const modifiersGroups = clonedeep(state.modifiersGroups);
  const modifierGroupId = action.modifierGroupId;
  const oldIndex = action.oldIndex;
  const newIndex = action.newIndex;

  const groupIndex = modifiersGroups.findIndex(x => x.id === modifierGroupId);
  modifiersGroups[groupIndex].modifiers = arrayMove(modifiersGroups[groupIndex].modifiers, oldIndex, newIndex);

  return { ...state, modifiersGroups };
}

const sortVariationModifiers = (state, action) => {
  const menus = clonedeep(state.menus);
  const variationId = action.variationId;
  const oldIndex = action.oldIndex;
  const newIndex = action.newIndex;

  let menuIndex;
  let categoryIndex;
  let subcategoryIndex;
  let itemIndex;
  let variationIndex;

  menus.forEach((menu, mIndex) => {
    menu.categories.forEach((category, cIndex) => {
      category.subcategories.forEach((subcategory, sIndex) => {
        subcategory.items.forEach((item, iIndex) => {
          item.variations.forEach((variation, vIndex) => {
            if (variation.id === variationId) {
              menuIndex = mIndex;
              categoryIndex = cIndex;
              subcategoryIndex = sIndex;
              itemIndex = iIndex;
              variationIndex = vIndex;
            }
          });
        });
      });
    });
  });

  menus[menuIndex]
    .categories[categoryIndex]
    .subcategories[subcategoryIndex]
    .items[itemIndex]
    .variations[variationIndex]
    .modifier_groups = arrayMove(menus[menuIndex]
                                  .categories[categoryIndex]
                                  .subcategories[subcategoryIndex]
                                  .items[itemIndex]
                                  .variations[variationIndex]
                                  .modifier_groups,
                                oldIndex, newIndex);

  return { ...state, menus };
}


const menuBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.MENU_BUILDER_SET_LOCATION_ID: return setLocationId(state, action.id);
    case actions.MENU_BUILDER_SET_MENUS: return setMenu(state, action.menus);
    case actions.MENU_BUILDER_SET_MODIFIER_GROUPS: return setModifierGroups(state, action.modifiersGroups);
    case actions.MENU_BUILDER_SORT_CATEGORIES: return sortCategories(state, action);
    case actions.MENU_BUILDER_SORT_SUBCATEGORIES: return sortSubategories(state, action);
    case actions.MENU_BUILDER_SORT_ITEMS: return sortItems(state, action);
    case actions.MENU_BUILDER_SORT_MODIFIERS: return sortModifiers(state, action);
    case actions.MENU_BUILDER_SORT_VARIATION_MODIFIERS: return sortVariationModifiers(state, action);
    default: return state;
  }
}

export default menuBuilderReducer;