import * as actionTypes from '../actions/actionTypes';

const initialState = {
  authenticated: false,
  user: null,
  token: ''
};


const auth = (state, user) => {
  return {
    ...state,
    authenticated: true,
    user,
    token: user.api_token
  };  
}

const revokeAuth = (state) => {
  return {
    ...state,
    authenticated: false,
    user: null,
    token: ''
  };
}


const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN: return auth(state, action.user);
    case actionTypes.LOGOUT: return revokeAuth(state);
    default: return state;
  }
}

export default authReducer;