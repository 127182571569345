import React from "react";
import PropTypes from 'prop-types';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

function Layout(props) {
  const whenLogged = (
    <main>
      <NotificationContainer/>

      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <div className="content">

            <Header pageTitle={props.pageTitle} onLogout={props.onLogout}/>
            
            <div className="page-content-wrapper">
              <div className="container-fluid">
                {props.children}
              </div>
            </div>
          </div>
          
          <Footer />
        </div>

      </div>
    </main>
  );

  const whenNotLogged = <main>{props.children}</main>;


  return props.authenticated ? whenLogged : whenNotLogged;
}

Layout.propTypes = {
  pageTitle: PropTypes.string,
  authenticated: PropTypes.bool,
  onLogout: PropTypes.func,
  preloader: PropTypes.bool
}


export default Layout;
