import React from 'react'
import { MDBBtn } from 'mdbreact';
import './Modal.css';


const body = props => {
  const size = `modal-${props.size || 'lg'}`;

  return (
    <div id={props.id} className="modal fade" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className={`modal-dialog ${size}`} role="document">
          <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title mt-0">{props.title}</h5>
                  <button type="button" className="close" data-toggle="modal" href={`#${props.id}`} aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body">
                {props.children}
              </div>
              <div className="modal-footer">
                  {props.save ? <MDBBtn color="success" onClick={props.save}>Save</MDBBtn> : null}
                  <MDBBtn color="secondary" data-toggle="modal" href={`#${props.id}`}>Close</MDBBtn>
              </div>
          </div>
      </div>
    </div>
  );
}

const button = props => {
  const color = props.color || 'primary';

  return (
    <MDBBtn color={color} data-toggle="modal" data-target={`#${props.modalId}`}>
      {props.children}
    </MDBBtn>
  );
}

const customButton = props => {
  return (
    <span data-toggle="modal" data-target={`#${props.modalId}`}>
      {props.children}
    </span>
  );
}

export default {
  body,
  button,
  customButton
};