import * as actionTypes from '../actionTypes';
import axios from '../../../axios';
import { getMenus } from './menus';
import { sort } from './_utils';

const setSortItems = (subcategoryId, oldIndex, newIndex) => {
  return {
    type: actionTypes.MENU_BUILDER_SORT_ITEMS,
    subcategoryId,
    oldIndex,
    newIndex
  }
}

// PUBLIC CLASSES 

export const sortItems = ({ subcategoryId, oldIndex, newIndex, selectedItemId, replacedItemId, successCallback }) => {
  return dispatch => {
    sort('items', oldIndex, newIndex, selectedItemId, replacedItemId, successCallback)
    dispatch(setSortItems(subcategoryId, oldIndex, newIndex));
  }
}

export const addItem = ({ data, successCallback }) => {
  return dispatch => {
    axios.post(`/menu_builder/items`, data)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}

export const deleteItem = ({ id, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/items/${id}`)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}

export const deleteItemImage = ({ id, successCallback }) => {
  return dispatch => {
    axios.delete(`/menu_builder/items/${id}/image`)
      .then(res => {
        successCallback();
        dispatch(getMenus());
      });
  }
}

export const updateItem = ({ id, data, headers = null, successCallback }) => {
  return dispatch => {
    if (data instanceof FormData) {
      data.append('_method', 'PATCH');
      axios.post(`/menu_builder/items/${id}`, data, { headers })
        .then(res => {
          successCallback();
          dispatch(getMenus());
        });
    } else {
      axios.patch(`/menu_builder/items/${id}`, data)
        .then(res => {
          successCallback();
          dispatch(getMenus());
        });
    }
  }
}