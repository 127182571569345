import axios from 'axios';
import { NotificationManager } from 'react-notifications';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL
});

export const setAuthToken = token => {
  instance.defaults.headers['Authorization'] = `Bearer ${token}`;
}

// set headers
instance.defaults.headers['Accept'] = 'application/json';


// set interceptors
instance.interceptors.response.use(response => {
  if (response.data.errors) {
    console.log(response);
    let msg = '';
    for(let key in response.data.errors)
      msg += response.data.errors[key] + '. ';

    NotificationManager.error(msg);
    return Promise.reject(msg);
  }
  return response;
}, error => {
  console.log('error: ', error);
  console.log('error.response: ', error.response);
  
  if (error.response && error.response.data && error.response.data.errors) {
    NotificationManager.error(Object.values(error.response.data.errors).join('. '));
  } else {
    NotificationManager.error(error.message || error);
  }
  throw Promise.reject(error);
});



export default instance;